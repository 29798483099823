import { ApolloLink } from '@apollo/client'
import { captureMessage, setTag } from '@sentry/browser'
import { MIN_SIZE_TO_COMPRESS } from 'api/src/common/constants'
import { CUSTOM_HTTP_HEADERS } from 'api/src/common/enums'
import { compress } from 'brotli-compress'

import { RedwoodApolloLinks } from '@redwoodjs/web/apollo'

import { emitRefreshClient } from './lib/hooks/useRefreshClient'
import { LocalStorageKeys } from './types/enums'
const webCommitHash = process.env.GIT_COMMIT_HASH
const webCommitTime = process.env.GIT_COMMIT_TIME

const minCompressSize = (localStorage.getItem('minCompressSize') ||
  MIN_SIZE_TO_COMPRESS) as number //Fallback value is 5_000_000
const compressQuery = localStorage.getItem('compressQuery') === 'true'

export const hubsFetch: WindowOrWorkerGlobalScope['fetch'] = async (
  input,
  init,
) => {
  // Set the following localstorage keys to enable request compression:
  /* Run this in the browser console:
    localStorage.setItem('compressQuery', true)
    localStorage.setItem('minCompressSize', 100)
   */
  // minCompressSize byte size is up to the developer's discretion,
  // fallback is 5_000_000, if not set
  if (compressQuery) {
    const bodyString = JSON.stringify(init.body)
    if (bodyString.length > minCompressSize) {
      const body = JSON.parse(bodyString)
      const compressedBody = await compress(new TextEncoder().encode(body))

      const logMessage = `Compressed query with payload size ${bodyString.length} down to ${compressedBody.length}`
      captureMessage(logMessage, 'log')

      init.body = JSON.stringify({
        data: compressedBody.toString(),
      })
      init.headers = {
        ...init.headers,
        'Content-Encoding': 'br',
        'Content-Length': compressedBody.length.toString(),
      }
    }
  }

  // user-client-id is set in Routes.tsx
  const userClientId = localStorage.getItem(LocalStorageKeys.USER_CLIENT_ID)
  init.headers = {
    ...init.headers,
    [CUSTOM_HTTP_HEADERS.USER_CLIENT_ID]: userClientId,
  }

  return fetch(input, init)
}

const refreshClientState = {
  value: false,
}

export const getHubsLink = () => (rwLinks: RedwoodApolloLinks) => {
  const hashLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const ctx = operation.getContext()

      const headers: Headers = ctx?.response?.headers

      if (headers) {
        const apiCommitHash = headers.get('Api-Git-Commit-Hash')
        const apiCommitTime = headers.get('Api-Git-Commit-Time')

        setTag('apiCommitHash', apiCommitHash)
        setTag('apiCommitTime', apiCommitTime)

        const commitMismatch =
          apiCommitHash !== webCommitHash || apiCommitTime !== webCommitTime

        if (commitMismatch && !refreshClientState.value) {
          captureMessage('Web & Api Commit hashes do not match', {
            level: 'warning',
            extra: {
              apiCommitHash,
              apiCommitTime,
              webCommitHash,
              webCommitTime,
            },
          })

          emitRefreshClient({ refreshClient: true })
          refreshClientState.value = true
          setTag('commitMismatch', 'true')
        } else if (!commitMismatch && refreshClientState.value) {
          emitRefreshClient({ refreshClient: false })
          refreshClientState.value = false
          setTag('commitMismatch', undefined)
        }
      }
      return response
    })
  })

  return ApolloLink.from([hashLink, ...rwLinks.map(({ link }) => link)])
}
