// This page will be rendered when an error makes it all the way to the top of the
// application without being handled by a Javascript catch statement or React error
// boundary.
//
// You can modify this page as you wish, but it is important to keep things simple to
// avoid the possibility that it will cause its own error. If it does, Redwood will
// still render a generic error page, but your users will prefer something a bit more
// thoughtful :)

import { useContext, useEffect, useState } from 'react'

import { captureEvent, captureException } from '@sentry/browser'

import { navigate, routes } from '@redwoodjs/router'
// This import will be automatically removed when building for production
import { DevFatalErrorPage } from '@redwoodjs/web/dist/components/DevFatalErrorPage'

import Button from 'src/components/Library/Button/Button'
import { AuthClientContext } from 'src/lib/AuthContext'
import useAnalytics, { Categories } from 'src/lib/hooks/useAnalytics'
import TheHubIcon from 'src/lib/icons/TheHubIcon/TheHubIcon'
import { useAuth } from 'src/Providers'
import { DefaultAuthClient } from 'web/src/lib/authClients'

const IS_DEVELOPMENT = ['development', 'local'].includes(
  process.env.ENVIRONMENT,
)

export default DevFatalErrorPage ||
  (({ error }) => {
    // Added Intercom Chat
    const { currentUser } = useAuth()
    const [_, setAuth] = useContext(AuthClientContext)
    const chatId = process.env.FRONT_THE_HUB_CHAT_ID
    const [openChat, setOpenChat] = useState(false)
    const { trackEvent } = useAnalytics()

    useEffect(() => {
      if (chatId && openChat && window.Intercom) {
        window.Intercom('show')

        window.Intercom('onHide', function () {
          setOpenChat(false)
        })
      }
    }, [currentUser, openChat, chatId])

    const errStr = error.toString()
    const missingPool = errStr
      .toLowerCase()
      .includes('this.pool.getclientid is not a function')
    const dynamicImportError = errStr
      .toLowerCase()
      .includes('Failed to fetch dynamically imported module')

    useEffect(() => {
      if (missingPool) setAuth(DefaultAuthClient)
    }, [missingPool, setAuth])

    captureEvent({
      message: `Fatal Error Page: ${error?.message ?? 'Unknown Error'}`,
      level: 'fatal',
      extra: {
        missingPool,
        dynamicImportError,
        error,
      },
    })

    trackEvent(Categories.Uncategorized, 'hit fatal error page', {
      missingPool,
      dynamicImportError,
      errorMessage: error?.message,
    })

    return (
      <main
        className="flex flex-1"
        style={{
          // eslint-disable-next-line quotes
          backgroundImage: "url('/default-gradient.png')",
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <style
          dangerouslySetInnerHTML={{
            __html: `
              html, body {
                margin: 0;
              }
              html * {
                box-sizing: border-box;
              }
              .gradient-canvas {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #3a3aff;
                --gradient-color-1: #0d9df0;
                --gradient-color-2: #3a3aff;
                --gradient-color-3: #0d9df0;
                --gradient-color-4: #3a3aff;
              }
              main {
                display: flex;
                align-items: center;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
                text-align: center;
                background-color: #E2E8F0;
                height: 100vh;
                margin: autp 0
              }
              section {
                position: relative;
                width: 32rem;
                margin: 0 auto;
              }
              h1 {
                font-size: 2rem;
                margin: 0;
                font-weight: 700;
                line-height: 1;
              }
              p {
                font-size: 1.25rem;
                margin-top: 2rem;
                font-weight: 500;
                line-height: 1.5;
                text-align: center;
              }
              button {
                background-color: rgb(3 142 253);
                border-radius: 0.25rem;
                border: none;
                color: white;
                font-size: 1rem;
                padding: 0.5rem 3rem;
                cursor: pointer;
                transition: background-color 0.2s ease-in-out;
              }
            `,
          }}
        />

        <section>
          <div className="relative sm:mx-auto sm:w-full sm:max-w-md">
            <div className="justify-center space-x-2">
              <TheHubIcon variant="white" />
            </div>
          </div>
          <div className="mt-16 rounded-lg bg-white px-10 py-8 shadow">
            <h1 className="text-gray-700">
              {dynamicImportError ? (
                <div>
                  A new version of the site has been deployed
                  <Button onClick={() => location.reload()}>Refresh</Button>
                </div>
              ) : (
                <span>Something went wrong!</span>
              )}
            </h1>
            {!dynamicImportError ? (
              <p className="text-gray-700">
                If you have a moment to spare, please let us know what happened
                (Error has been logged).
              </p>
            ) : (
              <>
                <p className="text-gray-700">
                  Error has been logged, and will be looked at.
                </p>
                <p className="pt-5 text-red-600">
                  Error: {error.toString().substring(0, 1000)}
                </p>
              </>
            )}
            <div className="flex1 mt-4 flex flex-col text-gray-700">
              <Button
                fullWidth={false}
                className="mx-auto w-52"
                onClick={() => setOpenChat(true)}
              >
                Open Chat
              </Button>
              <Button
                fullWidth={false}
                variant="outlined"
                onClick={() => {
                  // navigate back to the home page
                  navigate(routes.home())
                  // reload the page
                  location.reload()
                }}
                className={'mx-auto mt-4 w-52'}
              >
                Go Back
              </Button>
            </div>
          </div>
        </section>
      </main>
    )
  })
