import { ENVIRONMENT } from './enums'

// process.env.ENVIRONMENT: Set in the `.env` file and infrastructure/playwright/run-playwright.bash

// 🟢 Use these functions, so they can be mocked in tests
export const isLocalFn = () => process.env.ENVIRONMENT === ENVIRONMENT.LOCAL
export const isDevelopmentFn = () =>
  process.env.ENVIRONMENT === ENVIRONMENT.DEVELOPMENT
export const isProductionFn = () =>
  process.env.ENVIRONMENT === ENVIRONMENT.PRODUCTION
export const isStagingFn = () => process.env.ENVIRONMENT === ENVIRONMENT.STAGING
/** Set in .github/workflows/*.yml */
export const isGHActionFn = () => process.env.IS_GH_ACTION === 'true'

// ⚠️ @deprecated Use the functions above instead
/** @deprecated use isLocal instead */
export const isLocal = process.env.ENVIRONMENT === ENVIRONMENT.LOCAL
/** @deprecated use isProductionFn instead */
export const isProduction = process.env.ENVIRONMENT === ENVIRONMENT.PRODUCTION
/** @deprecated use isStagingFn instead */
export const isStaging = process.env.ENVIRONMENT === ENVIRONMENT.STAGING
/** @deprecated use isDevelopmentFn instead */
export const isDevelopment = process.env.ENVIRONMENT === ENVIRONMENT.DEVELOPMENT
/** @deprecated use isGHActionFn instead */
export const isGHAction = process.env.IS_GH_ACTION === 'true'
