import { WifiIcon } from '@heroicons/react/24/solid'

import { navigate, routes } from '@redwoodjs/router'

import Button from 'src/components/Library/Button/Button'

const StatuspageLink = ({ indicator }) => {
  let badgeColor = 'text-gray-400'

  switch (indicator) {
    case 'critical':
      badgeColor = 'text-red-500'
      break
    case 'major':
      badgeColor = 'text-red-500'
      break
    case 'minor':
      badgeColor = 'text-orange-400'
      break
    case 'maintenance':
      badgeColor = 'text-orange-400'
      break
    case 'statuspageError':
      badgeColor = 'text-purple-500'
      break
    case 'loading':
      badgeColor = 'text-gray-400'
      break
    case 'empty':
      badgeColor = 'text-purple-500'
      break
    case 'failure':
      badgeColor = 'text-purple-500'
      break
    default:
      badgeColor = 'text-green-500'
      break
  }

  return (
    <div key="navStatus" className="flex items-center justify-center">
      <Button
        id="status-page-menu-btn"
        variant="text"
        onClick={() => {
          navigate(routes.status())
        }}
        className="sidebar-item-default text-gray-500"
      >
        <WifiIcon className={`${badgeColor}`} aria-hidden="true" />
        <div className="sidebar-expanded-link">Status</div>
      </Button>
    </div>
  )
}

export default StatuspageLink
