import {
  AcademicCapIcon,
  ChartPieIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline'

import { routes } from '@redwoodjs/router'

import { NavigationItem } from './SidebarLink'

interface GetPeopleMenuProps {
  isMobile: boolean
  informerDirectoryLive: boolean
  orgChartLive: boolean
}

export const getPeopleMenu = ({
  isMobile,
  informerDirectoryLive,
  orgChartLive,
}: GetPeopleMenuProps): NavigationItem => {
  if (!informerDirectoryLive && !orgChartLive) {
    return null
  }

  const peopleDirectory: NavigationItem = {
    id: 'directory-menu',
    name: 'People',
    href: routes.informerDirectory(),
    icon: UserGroupIcon,
  }

  const peopleMenuChildren: NavigationItem[] = []

  if (informerDirectoryLive) {
    if (isMobile) {
      return peopleDirectory
    }

    peopleMenuChildren.push(peopleDirectory)
  }

  if (orgChartLive) {
    peopleMenuChildren.push({
      id: 'org-chart-menu',
      name: 'Organisation Chart',
      href: routes.informerOrgChart(),
      icon: UserGroupIcon,
      beta: false,
    })
  }

  let peopleMenuDesktop: NavigationItem = null

  if (peopleMenuChildren.length === 2) {
    // Call the people menu "Directory" since it has a parent folder
    peopleMenuChildren[0].name = 'Directory'

    peopleMenuDesktop = {
      id: 'people-menu',
      name: 'People',
      path: '/app/people',
      href: '#',
      icon: UserGroupIcon,
      children: peopleMenuChildren,
    }
  } else if (peopleMenuChildren.length === 1) {
    // if there is only 1 feature live then just return that as a menu item with no folder
    peopleMenuDesktop = peopleMenuChildren[0]
  }

  return peopleMenuDesktop
}

interface GetLearnMenuProps {
  isMobile: boolean
  learnerCoursesLive: boolean
  courseReportingLive: boolean
  isEditorOrAbove: boolean
}

export const getLearnMenu = ({
  isMobile,
  learnerCoursesLive,
  courseReportingLive,
  isEditorOrAbove,
}: GetLearnMenuProps): NavigationItem => {
  if (!learnerCoursesLive && !courseReportingLive) {
    return null
  }

  const learnMenu: NavigationItem = {
    id: 'learn-course-menu',
    name: 'Learn',
    href: routes.learnerCategories(),
    icon: AcademicCapIcon,
  }

  const learnMenuChildren: NavigationItem[] = []

  if (learnerCoursesLive) {
    if (isMobile) {
      return learnMenu
    }

    learnMenuChildren.push(learnMenu)
  }

  if (courseReportingLive && isEditorOrAbove) {
    learnMenuChildren.push({
      id: 'learn-course-reporting-menu',
      name: 'Course Reporting',
      href: routes.learnerCourseReports(),
      icon: AcademicCapIcon,
    })
  }

  let learnMenuDesktop: NavigationItem = null

  if (learnMenuChildren.length === 2) {
    // Call the Learn menu "Courses" since it has a parent folder
    learnMenuChildren[0].name = 'Courses'

    learnMenuDesktop = {
      id: 'learn-menu',
      name: 'Learn',
      path: '/app/learn',
      href: '#',
      icon: AcademicCapIcon,
      children: learnMenuChildren,
    }
  } else if (learnMenuChildren.length === 1) {
    // For e2es, the id of the item should be 'learn-menu'
    // regardless if it has children or not
    learnMenuChildren[0].id = 'learn-menu'
    learnMenuDesktop = learnMenuChildren[0]
  }

  return learnMenuDesktop
}

interface GetInsightsMenuProps {
  isMobile: boolean
  npsLive: boolean
  revenueProfilesLive: boolean
}

export const getInsightsMenu = ({
  isMobile,
  npsLive,
  revenueProfilesLive,
}: GetInsightsMenuProps): NavigationItem => {
  if (isMobile) {
    return null
  }

  if (!npsLive && !revenueProfilesLive) {
    return null
  }

  const insightsMenuChildren: NavigationItem[] = []

  if (npsLive) {
    insightsMenuChildren.push({
      id: 'insights-nps-menu',
      name: 'NPS',
      href: routes.npsInsights(),
      icon: ChartPieIcon,
    })
  }

  let insightsMenuDesktop: NavigationItem = null

  if (insightsMenuChildren.length > 1) {
    insightsMenuDesktop = {
      id: 'Insights-menu',
      name: 'Insights',
      href: '#',
      icon: ChartPieIcon,
      path: '/app/insights',
      children: insightsMenuChildren,
    }
  } else if (insightsMenuChildren.length === 1) {
    insightsMenuDesktop = insightsMenuChildren[0]
  }

  return insightsMenuDesktop
}
