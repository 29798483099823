import { useEffect } from 'react'

import * as Sentry from '@sentry/browser'
import { HUBS_URL } from 'api/src/common/enums'
import {
  isLocalFn,
  isProductionFn,
  isStagingFn,
} from 'api/src/common/environment'

const tracePropagationTargets = [
  HUBS_URL.LOCAL,
  HUBS_URL.DEVELOPMENT,
  HUBS_URL.STAGING,
  HUBS_URL.PRODUCTION,
]

const sentryClient = {
  value: null as ReturnType<typeof Sentry.init>,
}

export const useSentry = (params: { browserTabId: string }) => {
  useEffect(() => {
    const { browserTabId } = params
    const dsn = process.env.SENTRY_DSN_FRONTEND

    if (sentryClient.value || !dsn || (!isProductionFn() && !isStagingFn())) {
      return
    }

    const environment = process.env.ENVIRONMENT
    const webCommitHash = process.env.GIT_COMMIT_HASH
    const webCommitTime = process.env.GIT_COMMIT_TIME
    const deploymentSlot = process.env.DEPLOYMENT_SLOT

    sentryClient.value = Sentry.init({
      dsn: process.env.SENTRY_DSN_FRONTEND,
      environment,
      initialScope(scope) {
        scope.setTag('commitHash', webCommitHash)
        scope.setTag('commitTime', webCommitTime)
        scope.setTag('browserTabId', browserTabId)
        scope.setTag('deploymentSlot', deploymentSlot)
        return scope
      },
      release: webCommitHash,
      integrations: [
        Sentry.replayIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.browserTracingIntegration(),
      ],
      tracePropagationTargets,
      tracesSampleRate: isProductionFn() ? 0.5 : 0,
      profilesSampleRate: isProductionFn() ? 0.5 : 0,
      // Capture Replay for no sessions
      replaysSessionSampleRate: 0,
      // PROD: Capture ALL/100% of sessions with an error
      replaysOnErrorSampleRate: isProductionFn() ? 1.0 : 0,
      enabled: isLocalFn() ? false : true,
    })

    sentryClient.value.captureEvent({
      message: 'Sentry initialized',
      level: 'debug',
    })
  }, [])
}
